import React, { useEffect, useState } from "react";
import { Input } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

export default function IncrementFormatter(props) {

    const { label="Quantity", disabled = false, defaultValue, onChange = () => {}, min=1, max=9999, size="middle" } = props;


    const [value, setValue] = useState(parseFloat(defaultValue));

    useEffect(() =>{

        if(defaultValue !== value){
            setValue(parseFloat(defaultValue))
        }
    }, [defaultValue])


    const adjust = (e, _inc) => {

       
        if(props.checkCart){
            props.setCheckCart(false);
        }
        e.preventDefault();
        e.stopPropagation();

       

        if ((parseFloat(value + _inc)) >= min && (parseFloat(value + _inc) <= max)) {

            if((parseFloat(value) + parseFloat(_inc)) < min){
              
                return; 
            }

            if((parseFloat(value) + parseFloat(_inc)) > 9999){
               
                return; 
            }

            console.log(parseFloat(value) + parseFloat(_inc));

            setValue(parseFloat(value) + parseFloat(_inc));
            onChange(parseFloat(value) + parseFloat(_inc));
        }

    }

    const drawIncrement = () => {
        return (<div aria-hidden onClick={(e) => adjust(e, 1)} style={{ "width": "25px" }}><PlusOutlined size={size} style={{ "backgroundColor": "#fff", "fontSize": "9px", "color": (value >= max) ? "#eee" : "#aaa" }} /></div>)
    }

    const drawDecrement = () => {
    
        return (<div aria-hidden onClick={(e) => adjust(e, -1)} style={{ "width": "25px" }}><MinusOutlined size={size} style={{ "backgroundColor": "#fff", "fontSize": "9px", "color": (value <= min) ? "#eee" : "#aaa" }} /></div>)
    }

    const checkInt = (e) =>{

    

        if(!isNaN(parseFloat(e.target.value))){

            if(props.checkCart){
                props.setCheckCart(false);
            }
            
            setValue(parseFloat(e.target.value));
            onChange(e.target.value);
            
        } 

        else {

            setValue(min);
            onChange(min);
        }

    }

    const validateInt = (e) =>{

 
        if(!isNaN(parseFloat(e.target.value))){
            if ((e.target.value) <= min) {
                setValue(min);
                onChange(min);
              
            } 

            if ((e.target.value) >= max) {
                setValue(max);
                onChange(max);
              
            } 

        } else {

            setValue(min);
            onChange(min);
            
        }

    }

    const mute = (e) =>{
        e.preventDefault();
        e.stopPropagation();
    }
   
    if(props.checkCart){
        if(value > defaultValue){
            setValue(defaultValue);
        }
    }
   
    return (
        <>
            <Input disabled={disabled} aria-label={label} size={size} onKeyPress={(e) => {
                if(e.key === "Enter"){
                   e.target.blur();
                }
            }} onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)} inputMode="numeric" pattern="[0-9]*" onBlur={(v) => validateInt(v)} onChange={(v) => checkInt(v)} onClick={(e) => mute(e)} value={value}  className="inputNum" maxLength={(props.giftcard) ? 4 : 3}  style={{ "border" : "none", "width": (props.giftcard)? "100px" : "80px", "textAlign": "center", "padding": "0px 0px" }} addonAfter={<>{drawIncrement()}</>} addonBefore={drawDecrement()} />
        </>
    )
}