import React, { useEffect, useState } from "react"
import { Table, Row, Col, Divider, Button, Space, Card, Modal, Layout, Alert, Popconfirm, message, Spin, Checkbox } from 'antd';
import { StopOutlined, StarOutlined } from '@ant-design/icons';
import { apiCall } from "../../utilities/Api";
import { Link } from "react-router-dom";
import IncrementFormatter from "../../formatters/IncrementFormatter";
import moment from "moment";
import ClickAndCollect from "./ClickAndCollect";
import EmailCartList from "../../EmailCartList";
import ImportExcelToCart from "../../ImportExcelToCart";
import { useSession } from "../../../util/Session";
import GiftCardAddToCart from "../giftcards/GiftCardAddToCart";
import { pluralize, processAvailable } from "../../../util/Utils";
import { useHistory } from "react-router-dom";
import conditional from "../../utilities/conditional";
import RequestQuote from "./RequestQuote";
import { StarStrokeIcon } from "../../../util/Icons";
import PromoCodes from "./PromoCodes";
import GiftRegistryBadge from "../giftregistry/GiftRegistryBadge"

import LocationWarning from "../LocationWarning";
import ImpulsePurchases from "./ImpulsePurchases";
import BMAlert from "../../../util/BMAlert";
import LocationSelector from "../../LocationSelector";

export function CartSummary(props) {


    const [context,] = useSession();
    const { show_terms = false } = props;




    const MobileSticky = (props) => {
        return (
            <>
                <div className="show">
                    {props.children}
                </div>
                <div className="hide">
                    {props.children}
                </div>
            </>
        )
    }

    const drawPromoLineItems = (_arr, show_ship = false) => {
        if (!_arr) {
            return (<></>);
        }
        return _arr.map((item, index) => {
            if (((show_ship) && item.type !== "freeship") || (!show_ship) && item.type === "freeship") {
                return (<></>);
            }
            return (
                <Row>
                    <Col span={17}><span style={{ "color": "#e70d3d" }}><small>{item.description}</small></span></Col>
                    <Col span={7}><span style={{ "color": "#e70d3d", "textAlign": "right", "display": "block" }}><small>- ${item.amount}</small></span></Col>
                </Row>
            )
        })
    }

    const drawTaxes = (_arr) => {
        return _arr.map((item, index) => {
            return (
                <Row key={item}>
                    <Col span={17}><small>{(item.type) ? <>{item.type} </> : ""}</small></Col>
                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}><small>{"$" + item.amount}</small></span></Col>
                </Row>
            )
        })
    }

    const AcceptTerms = (props) => {

        const { show_terms } = props;
        const [showWarning, setShowWarning] = useState(window.sitesettings.checkout_info.terms_consent_enabled)

        if (window.sitesettings.checkout_info.terms_consent_enabled && show_terms) {
            return (
                <>
                    <div style={{ "marginTop": "15px" }}><Checkbox onChange={(e) => setShowWarning(!e.target.checked)}><p style={{ "fontSize": "13px" }}><nobr>I accept the <a href="/terms-conditions" target="_blank" title="Terms & Conditions" style={{ "color": "#069" }}>Terms & Conditions</a>.</nobr></p></Checkbox></div>
                    <div style={{ "marginTop": "-15px" }}>
                        <Spin style={{ "width": "250px" }} spinning={showWarning} indicator={<></>}>
                            {props.children}
                        </Spin>
                    </div>
                </>
            )
        } else {
            return props.children;
        }

    }

    const title_items = context.cart.summary.item_count + " " + pluralize(context.cart.summary.item_count, "title", "s") + ", " + context.cart.summary.total_quantity + " " + pluralize(context.cart.summary.total_quantity, "item", "s");

    return (<>
        <div className="show"><LocationWarning /></div>
        <MobileSticky>

            <PromoCodes />
            <Card className="adjust-margin" title={(<div><strong style={{ "fontSize": "18px" }}>Order Summary</strong><span style={{ "display": "block", "fontSize": "12px" }}>{title_items}</span></div>)}>

                <conditional.true value={(context.cart.summary.hasOwnProperty('original_price'))}>
                    <Row>
                        <Col span={17}>Price</Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}>{"$" + context.cart.summary.original_price}</span></Col>
                    </Row>
                </conditional.true>

                <conditional.true value={(context.cart.summary.discounts) && (context.cart.summary.discounts !== "0.00")}>
                    <Row>
                        <Col span={17}><span style={{ "color": "#e70d3d" }}><small>Discount</small></span></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}><small>{" - $" + context.cart.summary.discounts}</small></span></Col>
                    </Row>
                </conditional.true>

                <conditional.true value={(context.cart.summary.hasOwnProperty('promo_codes') && context.cart.summary.promo_codes.length > 0)}>
                    {drawPromoLineItems(context.cart.summary.promo_codes)}
                </conditional.true>

                <Row>
                    <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>
                    <Col span={17}>Subtotal</Col>
                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}>{"$" + context.cart.summary.subtotal}</span></Col>
                </Row>

                {drawTaxes(context.cart.summary.taxes)}

                <conditional.true value={(context.cart.summary.shipping !== "0.00")}>
                    <Row>
                        <Col span={17}><small>Shipping</small></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}><small>{"$" + context.cart.summary.shipping}</small></span></Col>
                    </Row>
                </conditional.true>

                <conditional.true value={(context.cart.summary.hasOwnProperty('promo_codes') && context.cart.summary.promo_codes.length > 0)}>
                    {drawPromoLineItems(context.cart.summary.promo_codes, true)}
                </conditional.true>

                <conditional.true value={(context.cart.summary.hasOwnProperty("total_gift_card_amount_used") && (context.cart.summary.total_gift_card_amount_used !== "0.00"))}>
                    <Row>
                        <Col span={17}><span style={{ "color": "#e70d3d" }}>Gift Cards</span></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}>{"-$" + context.cart.summary.total_gift_card_amount_used}</span></Col>
                    </Row>
                </conditional.true>

                <div aria-hidden><Divider style={{ "margin": "10px 0px", "marginBottom": "20px" }} /></div>
                <Row>
                    <Col span={17}><strong style={{ "fontSize": "18px", "color": "#202020" }}>Total</strong></Col>
                    <Col span={7}><strong><span style={{ "textAlign": "right", "display": "block", "fontSize": "16px", "color": "#202020" }}>{"$" + context.cart.summary.total}</span></strong></Col>
                </Row>

                <AcceptTerms show_terms={show_terms}>
                    <Row>
                        <Col span={24}>
                            <span style={{ "textAlign": "right", "display": "block" }}>
                                {props.children}
                            </span>
                        </Col>
                    </Row>
                </AcceptTerms>
            </Card>

        </MobileSticky>
    </>)
}

function Cart(props) {

    const [loading, setLoading] = useState("");
    const [cartPage, setCartPage] = useState((window.cartPage) || 1);
    const [hasSeenConfirm, setHasSeenConfirm] = useState({});
    const { saved, setSaved } = props;
    const [checkCart, setCheckCart] = useState(false);
    const [context, setContext] = useSession();
    const history = useHistory();
    const [confirm, setConfirm] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false)
    const [sending, setSending] = useState(false);





    const drawPickupText = () => {

        let _location_name = "";
        if (window.sitesettings.store_info.is_multistore && window.sitesettings.store_info.multistore_info.length > 0) {
            let _s = false;
            if (context.location_id) {
                _s = window.sitesettings.store_info.multistore_info.find(item => item.id == context.location_id);
            } else {
                
                _s = window.sitesettings.store_info.multistore_info[0];
            }
            _location_name = _s?.name;
            return (<strong>Pick up at: <div>{_location_name}</div></strong>)
        } else {
            return (<strong>Pick up in store</strong>)
        }
    }

    const drawSelectLocation = () => {

        if (window.sitesettings.store_info.is_multistore) {
            return (<><div className="shim" /><div className="shim" /><LocationSelector location_btn checkout><Button block>Pick up at a different location</Button></LocationSelector></>)
        } else {
            return (<></>)
        }

    }


    const removeAllUnavailable = () => {
        setRemoveLoading(true)
        apiCall("cart/removeUnavailable", {}, (_status, _result) => {
            if (_status) {
                setContext({ ...context, "cart": _result });
                message.success("Unavailable items removed from cart")
            } else {
                message.error("Error removing unavailable items")
            }
            setRemoveLoading(false);
        })
    }


    const drawRemoveAllUnavailable = () => {

        return (
            <>
                <br /> <div className="shim" /><div className="shim" /><Button loading={removeLoading} onClick={() => removeAllUnavailable()} size="small" type="danger"><small>Remove all unavailable items.</small></Button>
            </>
        )
    }


    const imageFallback = (i) => {
        i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
        i.target.className = "light-cart";
    }

    const getSession = () => {
        apiCall("session/get", { reset_cart: true }, (_status, _result) => {
            if (_status) {

                if (doubleCheck(_result)) {
                    window.checksum = _result.cart.checksum;
                    setContext(_result);
                } else {
                    doubleCheck(_result, true)
                }

            }
        })
    }

    useEffect(getSession, []);


    const onRemoveAllItemsFromCart = (_status, _result) => {
        if (_status) {
            setContext({ ...context, "cart": _result })
        }
    }

    const removeAllItemsFromCart = () => {
        apiCall("cart/removeAll", {}, onRemoveAllItemsFromCart);
    }

    const onMoveCartToWishlist = (_success, _result) => {
        if (_success) {
            // update context
            setContext({ ...context, "wishlist": _result.list, "cart": _result.cart })
            history.push("/account/lists");
        }
    }

    const moveCartToWishlist = () => {

        if (context.hasOwnProperty("wishlist_id")) {
            apiCall("cart/copyCartToList", { list_id: context.wishlist_id }, onMoveCartToWishlist)
        }

    }


    // Add To Cart 
    const onAddToCart = (_status, _result) => {
        if (_status) {
            setContext({ ...context, "cart": _result })
            setCheckCart(true);
            setCheckCart(false);
        } else {
            setError(true);
        }
    }


    const showConfirm = (_eisbn, _item, _quantity, _cart_id) => {

        if (hasSeenConfirm.hasOwnProperty(_cart_id)) {
            addToCart(_eisbn, _item.title_info.available.code, _quantity, _cart_id, _item, true);
            return;
        }
        if (_item.title_info.available.code === "") {
            setHasSeenConfirm({ ...confirm, [_cart_id]: true });
        }


        let m = Modal.warn({
            icon: null,
            centered: true,
            title: "Add to cart",
            width: "auto",
            style: { "padding": "0px" },
            content: <>
                <p>We only have <strong>{_item.title_info.available.onhand}</strong> in stock</p>
                <Space direction="vertical">
                    <Button onClick={() => returnItem(true)}>I'll take the {_item.title_info.available.onhand}</Button>
                    {/* <conditional.true value={context.logged_in}> */}
                    {(_item.title_info.available.is_extra_orderable && <Button onClick={() => returnItem(false)}>I'll take the {_item.title_info.available.onhand} but want the rest ordered</Button>)}
                    {/* </conditional.true> */}
                </Space>

            </>,
            okText: "Cancel",
            onOk: () => returnItem(true)
        });

        const returnItem = (_onlyonhand) => {
            m.destroy();
            addToCart(_eisbn, _item.title_info.available.code, (_onlyonhand) ? _item.title_info.available.onhand : _quantity, _cart_id, _item, true);
        }
    }



    const addToCart = (_eisbn, _code, _quantity, _cart_id, _item = null, force = false) => {


        if (!force && (parseInt(_item.title_info.available.onhand) > 0) && (parseInt(_quantity) > parseInt(_item.title_info.available.onhand))) {
            showConfirm(_eisbn, _item, _quantity, _cart_id);
            return;
        }

        let _args = {}
        if (_item.hasOwnProperty("personalized")) {
            _args["eisbn"] = _item.title_info.eisbn;
            _args["cart_id"] = _item.cart_info.cart_id;
            _args["quantity"] = _quantity;
            _args["price"] = _item.cart_info.cart_notes.giftcard_price;
            _args["personalized"] = _item.cart_info.cart_notes.gift_card_info.personalize;

            if (_args["personalized"]) {

                _args["from"] = _item.cart_info.cart_notes.gift_card_info.from;
                _args["to"] = _item.cart_info.cart_notes.gift_card_info.to;
                _args["message"] = _item.cart_info.cart_notes.gift_card_info.message;
            }


            apiCall("cart/add", _args, onAddToCart)
        } else {

            _args = { eisbn: _eisbn, condition: _code, quantity: _quantity, cart_id: _cart_id }


            if (_item.cart_info.gift_registry_id) {
                _args.gift_registry_id = _item.cart_info.gift_registry_id;
            }



            apiCall("cart/add", _args, onAddToCart)
        }


    }

    // Remove from Cart
    const onRemoveFromCart = (_status, _result) => {
        if (_status) {
            // update context
            setContext({ ...context, "cart": _result })
        } else {
            // error
        }
        setRemoveLoading(false);
    }

    const removeFromCart = (_cart_id) => {
        setRemoveLoading(true);
        apiCall("cart/removeItem", { cart_id: _cart_id }, onRemoveFromCart)

    }

    const onSetPickup = (_status, _result) => {
        setLoading("");
        if (_status) {
            window.checksum = _result.checksum;
            setContext({ ...context, "cart": _result });
            history.push("/checkout/contact");
        }
    }

    const onSetShip = (_status, _result) => {
        setLoading("");
        if (_status) {
            window.checksum = _result.checksum;
            setContext({ ...context, "cart": _result });
            history.push("/checkout/delivery");
        } else {
            Modal.error({
                icon: <></>,
                title: 'There was an error.',
                content: _result,
            });
        }
    }

    const doubleCheck = (_session, modal = false) => {
        let _flag = true;
        let _retitems = [];
        _session.cart.items.forEach(item => {
            let _xtra = item.title_info.available.is_extra_orderable;
            if (!_xtra) {
                let _ava = parseInt(item.title_info.available.onhand || 0);
                // if oh is a char they can overbuy... 
                if (!isNaN(_ava)) {
                    let _qty = item.cart_info.quantity;
                    if (_qty > _ava) {
                        _retitems.push(item);
                        _flag = false;
                    }
                }
            }
        });
        if (modal) {
            Modal.confirm({
                icon: null,
                title: "Cart adjustment",
                okText: "Update cart",
                onOk: () => {
                    _retitems.forEach(item => {
                        let qty = parseInt(item.title_info.available.onhand || 0);
                        if (qty) {
                            addToCart(item.title_info.eisbn, item.title_info.available.code, qty, item.cart_info.cart_id, item)
                        } else {
                            removeFromCart(item.cart_info.cart_id);
                        }
                    })
                },
                content: <>
                    <p>We no longer have the inventory to fulfill this order. Quantities will be adjusted. Titles with no inventory will be removed.</p>
                    <Table pagination={false} style={{ "border": "1px solid #eee", "borderBottom": "none" }} size={"small"}
                        columns={[
                            { title: '', dataIndex: ['title_info', 'eisbn'], key: 'eisbn', render: (e) => <img onError={(i) => imageFallback(i)} alt={e.eisbn} className="shadow-cart" style={{ "maxHeight": "50px", "maxWidth": "40px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + e} /> },
                            { title: <small>Title</small>, dataIndex: ['title_info', 'title'], key: 'title' },
                            { title: <small>Requested</small>, dataIndex: ['cart_info', 'quantity'], key: 'quantity', render: (e) => <strong style={{ "color": "#e70d3d" }}>{(e) ? e : "0"}</strong> },
                            { title: <small>New</small>, dataIndex: ['title_info', 'available', 'onhand'], key: 'quantity', render: (e) => <strong style={{ "color": "#48981f" }}>{(e) ? e : "0"}</strong> },
                        ]}
                        dataSource={_retitems}
                    />
                    {/* <pre>{JSON.stringify(_retitems, null, 2)}</pre> */}
                </>
            });
        }
        return _flag;
    }

    const setShip = (_step, _delivery_method, _custom = false) => {
        setLoading(_delivery_method)
        apiCall("session/get", {}, (_status, _result) => {
            if (_status) {
                setSending(false);
                if (doubleCheck(_result)) {
                    if (_result.cart.contains_unavailable) {
                        // cart is messed up, show unavailable
                        setContext(_result);
                        setLoading("");
                    } else {
                        setSaved({ ...saved, "delivery": _delivery_method, "shipping": {}, "custom": _custom });
                        apiCall("checkout/setDelivery", { delivery_preference: _delivery_method, custom: _custom }, onSetShip);
                    }
                } else {
                    doubleCheck(_result, true)
                    setLoading("")
                }
            } else {
                setLoading("")
            }
        })
    }

    const setPickup = (_step, _delivery_method, _custom = false) => {
        setLoading(_delivery_method)
        apiCall("session/get", {}, (_status, _result) => {
            if (_status) {
                if (doubleCheck(_result)) {

                    if (_result.cart.contains_unavailable) {
                        // cart is messed up, show unavailable
                        setContext(_result);
                        setLoading("");
                    } else {
                        setSaved({ ...saved, "delivery": _delivery_method, "shipping": {}, "custom": _custom });
                        apiCall("checkout/setDelivery", { delivery_preference: _delivery_method, custom: _custom }, onSetPickup);
                    }
                } else {
                    doubleCheck(_result, true)
                    setLoading("")
                }
            } else {
                setLoading("")
            }
        })
    }

    const [error, setError] = useState(false);

    const displayGiftCardItem = (item, i) => {

        let is_e_giftcard = item.cart_info.cart_notes?.gift_card_info?.recipient_email;

        // update input
        return {
            "key": item.title_info.eisbn + "" + item.title_info.available.code + i,
            "name": (<div><Link to={{ "pathname": "/item/" + item.title_info.eisbn }}><img onError={(i) => imageFallback(i)} alt={item.title_info.title} className="shadow-cart" style={{ "maxHeight": "100px", "maxWidth": "70px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + item.title_info.eisbn + "&cb=" + item.title_info.cover_image_cache} /></Link></div>),
            "title": (<div style={{ "lineHeight": "120%" }}>{item.title_info.title} {((item.cart_info.cart_notes.gift_card_info.personalize !== 0) && <><br /><span style={{ "color": "#069" }}><em>Personalized </em></span></>)} </div>),
            "binding": (<div><span>{item.title_info.binding}</span></div>),
            "condition": (<div><span>{item.title_info.available.text}</span></div>),
            "quantity": (<span style={{ "textAlign": "left", "display": "block" }}><Space direction="vertical"><div className="show">${item.cart_info.cart_notes.giftcard_price}</div>
                <div style={{ "pointerEvents": (!is_e_giftcard) ? "inherit" : "none", "opacity": (!is_e_giftcard) ? "1" : ".5" }}><IncrementFormatter disabled={is_e_giftcard} label={"Quantity in cart"} onChange={(_quantity) => addToCart(item.title_info.eisbn, item.title_info.available.code, _quantity, item.cart_info.cart_id, item)} defaultValue={item.cart_info.quantity} min={1} /></div>
                <Button onClick={() => removeFromCart(item.cart_info.cart_id)} danger size="small" ><small>Remove</small></Button><GiftCardAddToCart item={item} update row={i} cart_info={item.cart_info} eisbn={item.eisbn} cartable ><small>Edit gift card</small></GiftCardAddToCart></Space></span>),
            "price": (<div>${item.cart_info.cart_notes.giftcard_price}</div>)
        }
    }


    const displayCart = () => {
        const columns = [
            {
                "key": 0,
                "title": "Image",
                "dataIndex": "name"
            },
            {
                "key": 0,
                "title": "Title",
                "dataIndex": "title",
            },
            {
                "key": 0,
                "title": "Format",
                "dataIndex": "binding",
                "responsive": ["sm"]
            },
            {
                "key": 0,
                "title": "Availability",
                "dataIndex": "condition",
                "responsive": ["sm"]
            },
            {
                "key": 0,
                "title": "Quantity",
                "dataIndex": "quantity"
            },

            {
                "key": 0,
                "title": "Price",
                "dataIndex": "price",
                "responsive": ["sm"]
            },

        ];
        const drawLastOrdered = (_obj = {}) => {


            if (_obj.hasOwnProperty("date_ordered") && _obj.date_ordered) {
                return (<div><small style={{ "color": "#e70d3d" }}><em>You purchased this item on {moment(_obj.date_ordered * 1000).format("MMM Do YYYY")}</em></small></div>)
            } else {
                return (<></>);
            }
        }

        const drawCantShip = (_unshippable) => {
            if (_unshippable) {
                return (<div style={{ "lineHeight": "12px" }}><small style={{ "color": "#e70d3d" }}><strong>This product cannot be shipped. You may remove it from your cart to access shipping for other qualifying products.</strong></small><div className="shim" /></div>)
            }
        }

        const drawRemoveAllUnavailable = () => {

            return (
                <>
                    <br /> <div className="shim" /><div className="shim" /><Button onClick={() => removeAllUnavailable()} loading={removeLoading} size="small" type="danger"><small>Remove all unavailable items.</small></Button>
                </>
            )
        }

        const available = context.cart.items.filter((item => item.cart_info.cartable));
        const unavaliable = context.cart.items.filter((item => !item.cart_info.cartable));

        const fetchRow = (item, i, _cartable = true) => {


            let link_path = "/item/" + item.title_info.eisbn;
            if (item.cart_info.gift_registry_id) {
                link_path += "?registry=" + item.cart_info.gift_registry_id;
            }
            if (item.cart_info.is_giftcard) {
                // inject giftcard render
                return displayGiftCardItem(item, i);
            } else {
                return {
                    "key": item.title_info.eisbn + "" + item.title_info.available.code + i,
                    "name": (
                        <div>
                            <Link to={link_path}>
                                <GiftRegistryBadge gift_registry_id={item.cart_info.gift_registry_id}>
                                    <img onError={(i) => imageFallback(i)} alt={item.title_info.title} className="shadow-cart" style={{ "maxHeight": "100px", "maxWidth": "70px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + item.title_info.eisbn + "&cb=" + item.title_info.cover_image_cache} />
                                </GiftRegistryBadge>
                            </Link>
                        </div>
                    ),
                    "title": (<div style={{ "lineHeight": "120%" }}>{item.title_info.title}
                        <span style={{ "color": "#666" }} className="hide-block">
                            <small>{item.title_info.subtitle}</small>
                        </span>
                        {drawLastOrdered(item.cart_info.last_ordered)}
                        {drawCantShip(item.cart_info.unshippable)}
                        <span className="show">
                            <conditional.true value={(_cartable)}>
                                {/* <>{processAvailable([item.title_info.available], "mobile_cart", item.title_info.price, (context.logged_in || window.sitesettings.guest_show_price))}{((item.title_info.is_forthcoming && item.title_info.available.onhand === "") && <small><em style={{ "color": "#BD5800" }}>Releases: {moment(item.title_info.release_date * 1000).format('MMM DD, YYYY')}</em></small>)}</> */}
                                <conditional.true value={(item.cart_info.backorder_quantity > 0)}>
                                    <><div className="shim"></div><div style={{ "color": "#BD5800" }}>{item.cart_info.backorder_quantity} to be ordered.</div></>
                                </conditional.true>
                                <conditional.true value={((item.cart_info.backorder_quantity) && (!item.title_info.is_forthcoming))}>
                                    <><div className="shim"></div><small style={{ "color": "#BD5800" }}><em>{item.cart_info.backorder_sub_message}</em></small></>
                                </conditional.true>
                                <conditional.true value={((!item.title_info.available.onhand) && (!item.title_info.is_forthcoming))}>
                                    <div className="shim" />
                                    <small style={{ "color": "#BD5800" }}><em>{item.title_info.available.sub_message}</em></small>
                                </conditional.true>
                            </conditional.true>
                            <div>
                                {(!item.cart_info.cartable && !item.cart_info.cartable_logged_in) ? <span style={{ "color": "#ff4d4f" }}>Unavailable!</span> : <>{processAvailable([item.title_info.available], "mobile_cart", item.title_info.price, (context.logged_in || window.sitesettings.guest_show_price))}{((item.title_info.is_forthcoming && item.title_info.available.onhand === "") && <small style={{ "display": "block" }}><em style={{ "color": "#BD5800" }}>Releases: {moment(item.title_info.release_date * 1000).format('MMM DD, YYYY')}</em></small>)}
                                    {(item.cart_info.cartable_logged_in && !item.cart_info.cartable) && <div style={{ "color": "#e70d3d" }} ><small>(Requires account)</small></div>}
                                </>}
                            </div>
                        </span>
                    </div>),
                    "binding": (
                        <div>
                            {processAvailable([item.title_info.available], "format", item.title_info.price, (context.logged_in || window.sitesettings.guest_show_price))}
                            <span>
                                <small>{item.title_info.binding}</small>
                            </span>
                        </div>),
                    "condition": (
                        <div>
                            <span className="hide">
                                {(!item.cart_info.cartable && !item.cart_info.cartable_logged_in) ? <span style={{ "color": "#ff4d4f" }}>Unavailable!</span> : <>{processAvailable([item.title_info.available], "cart", item.title_info.price, (context.logged_in || window.sitesettings.guest_show_price))}{((item.title_info.is_forthcoming && item.title_info.available.onhand === "") && <small style={{ "display": "block" }}><em style={{ "color": "#BD5800" }}>Releases: {moment(item.title_info.release_date * 1000).format('MMM DD, YYYY')}</em></small>)}
                                    {(item.cart_info.cartable_logged_in && !item.cart_info.cartable) && <div style={{ "color": "#e70d3d" }} ><small>(Requires account)</small></div>}
                                </>}
                            </span>
                            <conditional.true value={(item.cart_info.backorder_quantity > 0)}>
                                <><div className="shim"></div><div style={{ "color": "#BD5800" }}>{item.cart_info.backorder_quantity} to be ordered.</div></>
                            </conditional.true>
                            <conditional.true value={((item.cart_info.backorder_quantity) && (!item.title_info.is_forthcoming))}>
                                <><div className="shim"></div><small style={{ "color": "#BD5800" }}><em>{item.cart_info.backorder_sub_message}</em></small></>
                            </conditional.true>
                            <conditional.true value={((!item.title_info.available.onhand) && (!item.title_info.is_forthcoming))}>
                                <div className="shim" />
                                <small style={{ "color": "#BD5800" }}><em>{item.title_info.available.sub_message}</em></small>
                            </conditional.true>
                        </div>),
                    "quantity": (
                        <span style={{ "textAlign": "left", "display": "block" }}>
                            <div className="show">${item.title_info.available.row_price}</div>
                            {((item.title_info.available.discount !== 0 && item.title_info.available.discount !== null) &&
                                <conditional.true value={(item.title_info.available.discount)}>
                                    <small className="show-block" ><em style={{ "color": "#e70d3d" }}>{item.title_info.available.discount + "% off $" + item.title_info.available.price}</em></small>
                                </conditional.true>
                            )}<div className="shim"></div>
                            {(_cartable && <IncrementFormatter
                                label={"Quantity in cart"}
                                setCheckCart={setCheckCart}
                                checkCart={checkCart}
                                onChange={(_quantity) => addToCart(
                                    item.title_info.eisbn,
                                    item.title_info.available.code,
                                    _quantity,
                                    item.cart_info.cart_id,
                                    item
                                )}
                                min={1}
                                defaultValue={item.cart_info.quantity} />)}
                            <div className="shim"></div><div className="shim"></div>
                            <Button
                                onClick={() => removeFromCart(item.cart_info.cart_id)}
                                danger
                                size="small"
                                loading={removeLoading}>
                                <small>
                                    Remove
                                </small>
                            </Button>
                        </span>),
                    "price": (<><div>{"$" + item.title_info.available.row_price}</div>{((item.title_info.available.discount !== 0 && item.title_info.available.discount !== null) &&

                        <conditional.true value={(item.title_info.available.discount)}>
                            <small>
                                <em style={{ "color": "#e70d3d" }}>{item.title_info.available.discount + "% off $" + item.title_info.available.price}</em>
                            </small>
                        </conditional.true>

                    )}</>)
                }
            }

        }


        const both = unavaliable.concat(available);

        const both_data = both.map((item, i) => {
            return fetchRow(item, i, false);
        })

        const all_data = context.cart.items.map((item, i) => {
            return fetchRow(item, i);
        })



        return (<div>


            <Layout className="responsiveSider row-colapse-reversed">
                <Layout.Sider tabIndex={0} width="none" className="responsiveSummary" theme="light">
                    <CartSummary show_terms={true} showShipping={false}>
                        {drawButtons()}
                    </CartSummary>
                </Layout.Sider>
                <br className="show" />
                <Layout.Content style={{ "width": "auto" }} >



                    <div className="hide"><LocationWarning /></div>

                    {((context.cart.contains_unavailable && context.logged_in) &&
                        <>
                            <div>
                                <BMAlert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>Unavailable items must be removed from the cart. {drawRemoveAllUnavailable()}</span></>} type="error" />
                                <br />
                                <Table ghost={true} pagination={true} dataSource={both_data} columns={columns}></Table>
                                <div aria-hidden><Divider /></div>
                            </div>
                        </>
                    )}

                    {((!context.logged_in || !context.cart.contains_unavailable) &&
                        <div>
                            <Table ghost={true} pagination={{ hideOnSinglePage: true, defaultCurrent: (window.cartPage) ? window.cartPage : 1, onChange: (e) => { window.cartPage = e; } }} dataSource={all_data} columns={columns}></Table>
                        </div>
                    )}


                    <br />
                    <Space size={5}>


                        <br className="show" />
                        {(context.logged_in && <EmailCartList />)}
                        {(context.logged_in && <ImportExcelToCart />)}

                        <Modal
                            open={confirm}
                            onOk={() => moveCartToWishlist()}
                            onCancel={() => setConfirm(false)}
                            okText="Ok"
                            closable={false}
                            cancelText="Cancel"
                        >
                            <br />
                            <p>Are you sure you want to add {context.cart.summary.item_count} {pluralize(context.cart.summary.item_count, "item", "s")} from your cart to your {(!window.sitesettings.store_info.is_american) ? "favourites" : "favorites"}?</p>
                        </Modal>

                        {(context.logged_in &&

                            <Popconfirm
                                title={(<>Copy {context.cart.summary.item_count} {pluralize(context.cart.summary.item_count, "item", "s")} to your {(!window.sitesettings.store_info.is_american) ? "favourites" : "favorites"}?</>)}
                                onConfirm={() => moveCartToWishlist()}
                                okText="Yes"
                                cancelText="No"
                            >

                                <Button style={{ "padding": "5px 5px" }} block icon={<StarOutlined aria-hidden />} type="text">Add to {(!window.sitesettings.store_info.is_american) ? "favs" : "favs"}</Button>
                            </Popconfirm>

                        )}
                        {(context.logged_in &&

                            <Popconfirm
                                title={(<>Remove {context.cart.summary.item_count} {pluralize(context.cart.summary.item_count, "item", "s")}?</>)}
                                onConfirm={() => removeAllItemsFromCart()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{ "padding": "5px 5px" }} block icon={<StopOutlined aria-hidden />} type="text">Remove</Button>
                            </Popconfirm>

                        )}
                    </Space>


                </Layout.Content>
                <br className="show" />
                <Layout.Sider width="none" className="responsiveSummary show-block" theme="light">
                    <CartSummary show_terms={true}>
                        {drawButtons()}
                    </CartSummary>
                </Layout.Sider>
            </Layout>
        </div>)
    }


    const drawButtons = () => {


        if (!context.cart.meets_order_min) {
            return (
                <>
                    <div className="shim" /><div className="shim" />
                    <Alert style={{ "padding": "2px 5px" }} type="error" description={<div style={{ "textAlign": "center" }}>Your order subtotal is below the minimum order requirement (<strong>${parseInt(window.sitesettings.checkout_info.order_min).toFixed(2)}</strong>)</div>} />
                </>
            )
        }


        return (
            <>
                <br />
                <ClickAndCollect>

                    <>
                        {((!context.logged_in && context.cart.summary.contains_only_emailed_gift_card) &&
                            <Button className="checkout-btn" block={true} disabled={(context.cart.contains_unavailable) ? true : false} onClick={() => setPickup("contact", "electronic")} style={{ "fontSize": "15px" }} type="primary"><strong>Checkout as Guest</strong></Button>
                        )}

                        {((!context.logged_in && !context.cart.summary.contains_only_emailed_gift_card) &&
                            <ImpulsePurchases setSession={setContext} session={context} delivery_type={"pickup"} callback={() => setPickup("contact", "pickup")} disabled={(context.cart.contains_unavailable) ? true : false}>
                                <Button loading={(loading === "pickup")} className="tallBtn" block disabled={(context.cart.contains_unavailable) ? true : false} onClick={() => setPickup("contact", "pickup")} type="primary">Checkout as Guest<em>for in-store pick up only</em></Button>
                            </ImpulsePurchases>
                        )}
                    </>
                </ClickAndCollect>

                {((context.cart.contains_unavailable && context.logged_in) && <><div className="shim"></div><Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>Cart contains unavailable items. {drawRemoveAllUnavailable()}</span></>} type="error" /><br /></>)}
                <div size={5} style={{ "width": "100%" }} direction={(!(window.sitesettings.checkout_info.ship_enabled && window.sitesettings.checkout_info.pickup_enabled && !context.cart.summary.contains_only_emailed_gift_card)) ? "vertical" : "horizontal"}>
                    {((context.logged_in && window.sitesettings.checkout_info.ship_enabled && !context.cart.summary.contains_only_emailed_gift_card) &&
                        <ImpulsePurchases setSession={setContext} session={context} delivery_type={"ship"} callback={() => setShip("delivery", "ship")} disabled={(context.cart.contains_unavailable) ? true : false}>
                            <Button className="checkout-btn" block disabled={(context.cart.contains_unavailable || context.cart.contains_unshippable) ? true : false} onClick={() => setShip("delivery", "ship")} style={{ "minWidth": "65px", "fontSize": "15px", "marginBottom": "5px" }} type="default"><strong>Ship</strong></Button>
                        </ImpulsePurchases>
                    )}
                    {((context.logged_in && window.sitesettings.checkout_info.pickup_enabled && !context.cart.summary.contains_only_emailed_gift_card) &&
                        <ImpulsePurchases setSession={setContext} session={context} delivery_type={"pickup"} callback={() => setPickup("contact", "pickup")} disabled={(context.cart.contains_unavailable) ? true : false}>
                            <Button className="checkout-btn" block disabled={(context.cart.contains_unavailable) ? true : false} onClick={() => setPickup("contact", "pickup")} style={{ "fontSize": "15px", "height": "auto", "textWrap" : "auto" }} type="primary">{drawPickupText()}</Button>
                        </ImpulsePurchases>
                    )}
                    {((context.logged_in && context.cart.summary.contains_only_emailed_gift_card) &&
                        <Button className="checkout-btn" block={true} disabled={(context.cart.contains_unavailable) ? true : false} onClick={() => setPickup("contact", "electronic")} style={{ "fontSize": "15px" }} type="primary"><strong>Checkout</strong></Button>
                    )}

                </div>

                {/* CUSTOM SHIP */}
                <conditional.true value={(window.sitesettings.checkout_info.active_custom_ship && context.logged_in && window.sitesettings.checkout_info.ship_enabled && !context.cart.summary.contains_only_emailed_gift_card)}>
                    <>
                        <div className="shim" />
                        <Button onClick={() => setShip("delivery", "ship", true)} disabled={(context.cart.contains_unavailable || context.cart.contains_unshippable)} block><strong>{window.sitesettings.checkout_info.label_custom_ship}</strong></Button>
                    </>
                </conditional.true>

                {/* CUSTOM PICKUP */}
                <conditional.true value={(window.sitesettings.checkout_info.active_custom_pickup && (context.logged_in || window.sitesettings.guest_allow_checkout) && window.sitesettings.checkout_info.pickup_enabled && !context.cart.summary.contains_only_emailed_gift_card)}>
                    <>
                        <div className="shim" />
                        <Button onClick={() => setPickup("contact", "pickup", true)} disabled={(context.cart.contains_unavailable)} block><strong>{window.sitesettings.checkout_info.label_custom_pickup}</strong></Button>
                    </>
                </conditional.true>

                <conditional.true value={(context.logged_in && context.user.alt_addresses.findIndex(item => item.shipping_encouraged) > -1)}>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <Alert style={{ "textAlign": "center" }} message={<><span style={{ "fontWeight": "600" }}>We recommend you choose the <u>Ship</u> option.</span> Your cart contains items part of a Gift Registry. The registry creator would prefer those items are shipped to an address of their choosing.</>} type="error" />
                </conditional.true>

                <conditional.true value={(!context.logged_in && context.cart.summary.contains_gift_registry_shipping)}>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <Alert style={{ "textAlign": "center" }} message={<>
                        <span style={{ "fontWeight": "600" }}>We recommend you log in or create an account.</span> Your cart contains items part of a Gift Registry. The registry creator would prefer those items are shipped to an address of their choosing. <span style={{ "fontWeight": "600" }}>Shipping is not available with Guest Checkout.</span></>} type="error" />
                </conditional.true>

                {drawSelectLocation()}

                <conditional.true value={(context.logged_in && window.sitesettings.checkout_info.quote_enabled)}>

                    <conditional.true value={(!context.cart.contains_unavailable && !context.cart.summary.contains_only_emailed_gift_card)}>
                        <RequestQuote />
                    </conditional.true>

                </conditional.true>

            </>
        )
    }

    const displayEmpty = () => {

        return (<div>Empty</div>)

    }

    const displayError = () => {

        return (<div>Error</div>)
    }

    const displayStatus = () => {



        if (error) return displayError();

        if (!context.cart.items || context.cart.items.length < 1) {
            return displayEmpty();
        } else {
            return displayCart();
        }

    }


    if (!window.sitesettings.cart_enabled) {
        return (<></>)
    }

    return displayStatus();
}

export default Cart;